<script setup>
import { ref, watch, computed } from "vue";

const props = defineProps({
  index: [String, Number],
  number: [String, Number],
  suffix: {
    type: String,
    default: null,
  },
  decimals: {
    type: String,
    default: null,
  },
  speed: {
    type: Number,
    default: 0,
  },
});

const animation = (speed, num) => {
  return {
    transform: "translateY(-" + (100 / 30) * (10 + num) + "%)",
    transition: "transform 200ms ease",
  };
};

const transition = computed(() => {
  return animation(props.speed, Math.ceil(props.number));
});

const placeholder = computed(() => {
  return props.index == 0 ? props.number : 0;
});
</script>

<template>
  <div class="key-counter-number">
    <span v-if="suffix" class="key-counter-number__suffix">{{ suffix }}</span>
    <span v-else-if="decimals" class="key-counter-number__decimals">{{
      decimals
    }}</span>
    <template v-else>
      <span class="key-counter-number__placeholder">{{ placeholder }}</span>
      <div class="key-counter-number__wrapper" :style="{ ...transition }">
        <div v-for="num in 10" :key="num">
          {{ num - 1 }}
        </div>
        <div v-for="num in 10" :key="num">
          {{ num - 1 }}
        </div>
        <div v-for="num in 10" :key="num">
          {{ num - 1 }}
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="postcss">
.key-counter-number {
  position: relative;
  display: inline-block;
  float: left;

  display: flex;

  font-family: var(--grotzec);
  font-size: units(8);
  text-align: right;
  transform: translateY(-5px);

  @media (--lg) {
    font-size: units(16.5);
  }
  /* transform: scale(0.25); */

  &__placeholder {
    visibility: hidden;
    opacity: 1;
  }

  &__decimals {
    /* color: green; */
  }

  &__suffix {
    margin-top: units(2.5);
    font-size: units(5);
    @media (--lg) {
      margin-top: units(7.6);
      font-size: units(8);
    }
  }

  &__wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
